import { SegmentProduct } from '../../../segmentInterface';

/**
 * Maps Shopify products to Segment format
 *
 * @param shopifyProducts - an array of products from Shopify cart or checkout object
 * @param priceIsInCents - cart product prices are in cents (100x), checkout prices are in dollars
 * @returns {SegmentProduct} - array of Segment products
 */
export const mapSegmentProducts = (shopifyProducts: LooseObject, priceIsInCents?: boolean): [SegmentProduct] => {
	return shopifyProducts.map((product: LooseObject) => ({
		brand: product.vendor,
		product_id: String(product.product_id),
		name: product.product_title || product.title,
		price: priceIsInCents ? parseFloat(product.price) / 100 : parseFloat(product.price),
		variant: product.variant_title,
		quantity: product.quantity,
		...(product.image && { image_url: product.image }),
		...(product.product_type && { category: product.product_type }),
		...(product.sku && { sku: product.sku }),
		...(product.variant_id && { shopify_variant_id: String(product.variant_id) }),
	}));
};
