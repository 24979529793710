import { MiddlewareOptions } from '../../../segmentInterface';
import { CustomWindow } from '../../../index.d';
import { segmentEventTypes } from '../constants';

declare let window: CustomWindow;

export const addEmailToEvents = ({ payload, next }: MiddlewareOptions): void => {
	try {
		if (![segmentEventTypes.page, segmentEventTypes.track].includes(payload.action())) return next(payload);

		payload.obj = {
			...payload.obj,
			properties: addEmailToProperties(payload.obj.properties),
		};
	} catch (ex) {
		console.error(ex);
	}
	next(payload);
};

export const addEmailToProperties = (properties: LooseObject): LooseObject => {
	const email = window.analytics?.user?.().traits().email || window.LittledataLayer.attributes?.['email-clientID'];

	if (email) {
		properties.email = email;
	}

	return properties;
};
