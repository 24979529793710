import { OwnLayer } from '../../../types';
import MD5 from 'crypto-js/md5';

export const possibleUserIds = {
	shopifyCustomerId: 'shopifyCustomerId',
	email: 'email',
	md5EmailHash: 'md5EmailHash',
	none: 'none',
};

const hash = (string: string) => MD5(string).toString();

export const addUserIdForCustomer = (LittledataLayer: OwnLayer): Record<string, string> => {
	const { customer, segmentUserId } = LittledataLayer;

	if (!customer) return {};

	switch (segmentUserId) {
		case possibleUserIds.email:
			if (!customer.email) return {};

			return { userId: customer.email };
		case possibleUserIds.md5EmailHash:
			if (!customer.email) return {};

			return { userId: hash(customer.email) };
		case possibleUserIds.none:
			return {};
		default:
			//before segmentUserId field was added
			return { userId: String(customer.id) };
	}
};
