/* eslint-env browser */
import { CustomWindow } from '../../index.d';
import { advertiseLD, documentReady, pageView, retrieveAndStoreClientId } from '../common/helpers';
import { setClientID } from '../common/setClientID';
import { callSegmentPage, trackEvents } from './helpers';
import { sendEventsWithPageview } from './helpers/sendEventsWithPageview';
import { initSegment } from './helpers/initSegment';
import { getTrackingConsent } from '../common/helpers/getTrackingConsent';
import { identifyCustomer } from './helpers/identifyCustomer';
import { retrieveAndStoreFacebookIds } from '../common/helpers/retrieveAndStoreFacebookIds';
import { urlToQueryStringObject } from '../common/helpers/urlToQueryStringObject';
import { adblockDetect } from '../common/adblockDetect';

declare let window: CustomWindow;

export const loadSegmentTracker = (): void => {
	adblockDetect(LittledataLayer.writeKey, 'segment');
	getTrackingConsent('segment').then(() => {
		initSegment();
		advertiseLD('Segment');
		identifyCustomer();
		retrieveAndStoreClientId();

		documentReady(() => {
			window.LittledataLayer.isDawnThemeActive = window?.Shopify?.theme?.name === 'Dawn';
			trackEvents();
		});
		pageView(function () {
			callSegmentPage({});
			sendEventsWithPageview(document.location.pathname);

			window.analytics.ready(() => {
				const { user } = window.analytics;

				if (!window.LittledataLayer.FacebookPixelID) {
					if (window.analytics.Integrations['Facebook Pixel']) {
						//if segment has facebook destination retrieve and store fbc and fbp from cookies
						retrieveAndStoreFacebookIds();
					} else {
						//if segment does not have facebook destination save fbc from query url if present
						const queryObj = urlToQueryStringObject(window.location.search);

						if (queryObj.fbclid) {
							const fbcClientId = `fb.1.${Date.now()}.${queryObj.fbclid}`;

							setClientID(fbcClientId, 'fbc');
						}
					}
				}

				//if segment does not have facebook destination
				//
				if (user) {
					setClientID(user().anonymousId(), 'segment');
					const { email } = user().traits();

					if (email) {
						setClientID(email, 'email');
					}
				}
			});
		});
	});
};
