import { addEmailToEvents } from './addEmailToEvents';
import { addGAClientIdToEvents } from './addGAClientIdToEvents';

export const initSegment = (): void => {
	// Create a queue, but don't obliterate an existing one!
	// @ts-ignore
	const analytics: any = (window.analytics = window.analytics || []);

	// If the real analytics.js is already on the page return.
	if (analytics.initialize) return;

	if (analytics.invoked) {
		window.console && console.error && console.error('Segment snippet included twice.');

		return;
	}

	analytics.invoked = true;
	analytics.methods = [
		'trackSubmit',
		'trackClick',
		'trackLink',
		'trackForm',
		'pageview',
		'identify',
		'reset',
		'group',
		'track',
		'ready',
		'alias',
		'debug',
		'page',
		'once',
		'off',
		'on',
		'addSourceMiddleware',
		'addIntegrationMiddleware',
		'setAnonymousId',
		'addDestinationMiddleware',
	];

	analytics.factory = function (t: any) {
		return function () {
			const e = Array.prototype.slice.call(arguments);

			e.unshift(t);
			analytics.push(e);

			return analytics;
		};
	};

	for (let t = 0; t < analytics.methods.length; t++) {
		const e = analytics.methods[t];

		analytics[e] = analytics.factory(e);
	}

	// use custom CDN path, or fallback to Segment's CDN
	const CDNdomain = LittledataLayer.CDNForAnalyticsJS || 'https://cdn.segment.com';

	// Define a method to load Analytics.js from CDN,
	// and that will be sure to only ever load it once.
	analytics.load = function (key: string, options: LooseObject) {
		// Create an async script element based on your key.
		const script = document.createElement('script');

		script.type = 'text/javascript';
		script.async = true;
		script.src = `${CDNdomain}/analytics.js/v1/${key}/analytics.min.js`;

		// Insert our script next to the first script element.
		const first = document.getElementsByTagName('script')[0];

		first.parentNode.insertBefore(script, first);
		analytics._loadOptions = options;
	};

	// Add a version to keep track of what's in the wild.
	analytics.SNIPPET_VERSION = '4.1.0';

	analytics.addSourceMiddleware(addEmailToEvents);
	analytics.addSourceMiddleware(addGAClientIdToEvents);
	analytics.load(LittledataLayer.writeKey);
	window.dataLayer = window.dataLayer || [];
};
