import { CustomWindow } from '../../../index.d';
import { getCookie } from '../../common/getCookie';
import { setCartOnlyAttributes, setClientID } from '../../common/setClientID';
import { getContext } from '../helpers';
import { addUserIdForCustomer } from './addUserIdForCustomer';

declare let window: CustomWindow;

export const identifyCustomer = (): void => {
	const { customer, cookiesToTrack = [] } = window.LittledataLayer;

	const cookieTraits: Record<string, string> = {};

	cookiesToTrack.forEach((cookie) => {
		cookieTraits[cookie] = getCookie(cookie);
	});
	setCartOnlyAttributes(cookieTraits); //this will add to Shopify cart

	if (!customer) return;

	const traits = {
		email: customer?.email,
		name: customer?.name,
		phone: customer?.phone || customer?.address?.phone,
		default_address: parseAddress(customer?.address),
		...cookieTraits,
	};
	const { userId } = addUserIdForCustomer(window.LittledataLayer);

	if (userId) {
		setClientID(userId, 'shopifyCustomer');
		getContext().then((context) => {
			return window.analytics.identify(userId, traits, { context });
		});
	} else {
		window.analytics.identify(traits);
	}
};

const parseAddress = (a: Customer['address']): SegmentAddressFormat => {
	if (!a) return {};
	const { address1, address2, city, country, zip, province } = a;

	return {
		street: `${address1}${address2 ? ', ' : ''}${address2}`,
		...(city && { city }),
		...(zip && { postalCode: zip }),
		...(province && { state: province }),
		...(country && { country }),
	};
};
