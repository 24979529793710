/* global LittledataLayer */
import { CustomWindow } from '../../index.d';
import { trackProductImageClicks, trackSocialShares } from '../common/helpers';
import { segmentProduct } from './helpers/segmentProduct';
import productListViews from '../common/productListViews';
import { getProductDetail } from '../common/getProductDetail';
import { listClickCallback } from '../common/addClickListener';
import { addUserIdForCustomer } from './helpers/addUserIdForCustomer';
import { httpRequest } from '../common/httpRequest';
import { LittledataPixel } from '../littledataPixel/LittledataPixel';

declare let window: CustomWindow;

const getOnetrustActiveGroups = () => {
	const { OnetrustActiveGroups } = window;

	return OnetrustActiveGroups && { OnetrustActiveGroups };
};

export const getContext = (): Promise<LooseObject> => {
	let shopifyCustomerId = window.LittledataLayer?.customer?.id;

	if (!shopifyCustomerId) {
		shopifyCustomerId = window.Shopify?.checkout?.customer_id;
	}

	return new Promise((resolve) => {
		const standardContext = {
			integration: {
				name: 'shopify_littledata',
				version: window.LittledataScriptVersion,
			},
			traits: {
				...(window.analytics.user && window.analytics.user().traits()),
				...getOnetrustActiveGroups(),
			},
			...(shopifyCustomerId && getExternalIds(shopifyCustomerId)),
		};

		if (!window.LittledataLayer.anonymizeIp) {
			return resolve(standardContext);
		}
		anonymizedIpResolve().then((anonymizedIpResponse: LooseObject) => {
			resolve({ ...standardContext, ...anonymizedIpResponse });
		});
	});
};

function getExternalIds(uid: string) {
	if (!uid) return;

	return {
		externalIds: [
			{
				id: uid,
				type: 'shopify_customer_id',
				collection: 'users',
				encoding: 'none',
			},
		],
	};
}

export const trackEvent = (eventName: string, params: LooseObject, callback?: any): void => {
	if (LittledataLayer.disableClientSideEvents) {
		if (LittledataLayer.debug) {
			console.log('Skipping Segment event', eventName);
		}

		return;
	}
	getContext().then((context: LooseObject) => {
		window.analytics.track(
			eventName,
			{
				sent_from: 'Littledata script',
				category: 'Shopify (Littledata)', // this should be overwritten by params.category
				...params,
				...addUserIdForCustomer(window.LittledataLayer),
				...getOnetrustActiveGroups(),
			},
			{ context },
			callback,
		);
		try {
			LittledataPixel.sendEvent({
				event_destination: 'segment',
				event_name: eventName,
				send_to: LittledataLayer.writeKey,
			});
		} catch (ex) {}
	});
};

export const trackEvents = (): void => {
	if (LittledataLayer) {
		/* run list, product, and clientID scripts everywhere */
		const clickTag = (product: Impression, element: TimeBombHTMLAnchor, openInNewTab: boolean) => {
			trackEvent(
				'Product Clicked',
				{
					...segmentProduct(product),
					list_id: product.list_name,
				},
				listClickCallback(element, openInNewTab),
			);
		};
		const impressionTag = (products: Impression[]) => {
			trackEvent('Product List Viewed', {
				list_id: products && products[0].list_name,
				products: products.map(segmentProduct),
			});
		};

		productListViews(impressionTag, clickTag);

		getProductDetail().then((productDetail) => {
			if (productDetail) {
				const product = segmentProduct(productDetail);

				// if PDP, we can also track clicks on images and social shares
				trackProductImageClicks((image) => {
					product.image_url = image.src;
					trackEvent('Product Image Clicked', product);
				});

				trackSocialShares((network) => {
					trackEvent('Product Shared', {
						...product,
						share_via: network,
					});
				});
			}
		});
	}
};

export const callSegmentPage = (integrations: Record<string, any>): void => {
	getProductDetail().then((productDetail) => {
		if (productDetail) {
			const properties = segmentProduct(productDetail);

			window.analytics.ready(() => {
				//need to wait for anonymousId to be available
				trackEvent('Product Viewed', properties);
			});
		}
	});

	// https://segment.com/docs/sources/website/analytics.js/#page
	const pageName = document.title;

	if (LittledataLayer.disablePageviews) {
		if (LittledataLayer.debug) {
			console.log('Skipped Segment pageview for', pageName);
		}

		return;
	}
	getContext().then((context: any) => {
		window.analytics.page(
			pageName,
			{},
			{
				context,
				integrations,
			},
		);
	});
};

const anonymizedIpResolve = () => {
	return new Promise((resolve) => {
		const sessionStorageKey = 'littledata_ip_address';
		const ipFromStorage = window.sessionStorage.getItem(sessionStorageKey);

		if (ipFromStorage) {
			return resolve({ ip: ipFromStorage });
		}
		httpRequest
			.getJSON('//api.ipify.org?format=json')
			.then((response: any) => {
				const { ip } = response;
				const anonymizedIp = ip.replace(/([0-9a-z]+)$/, 0);

				window.sessionStorage.setItem(sessionStorageKey, anonymizedIp);
				resolve({ ip: anonymizedIp });
			})
			.catch(() => {
				resolve({});
			});
	});
};
