import { trackEvent } from '../helpers';
import { getProductLinks } from '../../common/helpers/getProductLinks';
import { mapSegmentProducts } from '../../segmentThankYouPageTracker/helpers/mapSegmentProducts';
import { waitForObjectToLoad } from '../../common/helpers';

export const sendEventsWithPageview = (pathname: string): void => {
	if (pathname === '/cart') {
		waitForObjectToLoad(
			() => LittledataLayer.cart,
			() => {
				trackEvent('Cart Viewed', {
					cart_id: LittledataLayer.cart?.token,
					products: mapSegmentProducts(LittledataLayer.cart?.items, true),
				});
			},
		);
	}
	if (pathname === '/account/register') {
		trackEvent('Registration Viewed', {});
	}
	if (pathname === '/search') {
		trackSearch();
	}
};

/**
 * Tracks search event and results
 * @param secondAttempt - true when this is the 2nd attempt
 */
function trackSearch(secondAttempt?: boolean): void {
	const results = getProductLinks(document, LittledataLayer.productListLinksHaveImages).length;

	if (results == 0 || secondAttempt) {
		trackEvent('Products Searched', {
			query: document.location.search.replace('?q=', '').replace('+', ' '),
			results,
		});

		return;
	}
	// check again in case the products list took a long time to load
	window.setTimeout(() => {
		trackSearch(true);
	}, 2000);
}
