import { getCookie } from '../../common/getCookie';
import { setClientID } from '../../common/setClientID';
import { CustomWindow } from '../../../index.d';
import { CheckCallback, FoundCallback, waitForObjectToLoad } from '../../common/helpers';

declare let window: CustomWindow;

const checkCallback: CheckCallback = () => window?.fbq?.loaded;
const foundCallback: FoundCallback = (err: Error) => {
	if (err) {
		if (LittledataLayer.debug) {
			console.error(err);
		}

		return;
	}

	const fbpClientId = getCookie('_fbp');
	const fbcClientId = getCookie('_fbc');

	if (window?.fbq?.loaded && !fbpClientId) {
		//retry when fbp is not found correctly (this happens when segment has fb pixel as destination)
		//this is also happening for slow3g networks.
		return setTimeout(retrieveAndStoreFacebookIds, 500);
	}
	if (fbpClientId) {
		setClientID(fbpClientId, 'fbp');
	}

	if (fbcClientId) {
		setClientID(fbcClientId, 'fbc');
	}
};

export function retrieveAndStoreFacebookIds(): void {
	waitForObjectToLoad(checkCallback, foundCallback);
}
