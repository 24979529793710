import { IDetail } from '../../../types';
import { SegmentProduct } from '../../../segmentInterface';
import { convertProductHandleToURL } from '../../common/helpers/convertProductHandleToURL';
import { getActiveCurrency } from '../../common/helpers/getActiveCurrency';

export const segmentProduct = (dataLayerProduct: IDetail): SegmentProduct => {
	const { brand, category, name, variant, image_url, tags } = dataLayerProduct;
	const output: SegmentProduct = {
		brand,
		category,
		url: convertProductHandleToURL(dataLayerProduct.handle),
		product_id: String(dataLayerProduct.shopify_product_id),
		sku: dataLayerProduct.id,
		name,
		price: parseFloat(dataLayerProduct.price),
		variant,
		shopify_product_id: String(dataLayerProduct.shopify_product_id),
		shopify_variant_id: String(dataLayerProduct.shopify_variant_id),
		currency: getActiveCurrency(),
		image_url,
		tags,
	};

	if (dataLayerProduct.list_position) {
		output.position = dataLayerProduct.list_position;
	}
	if (dataLayerProduct.compare_at_price) {
		output.compare_at_price = dataLayerProduct.compare_at_price;
	}

	return output;
};
