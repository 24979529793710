import qs from 'qs-lite';

/**
 * Strips hash fragments out of query params
 * @param str - query param value. if last param in URL might include a hash frag
 * @returns {string} - returns the value stripped of hash frag, or same value if no hash is present
 */
const excludeHashValues = (str: string) => {
	return str.split(/#/)[0];
};
/**
 * Converts "true" and "false" to corresponding booleans. Returns same value if not "true"||"false"
 * @param value - query param value
 * @returns {string|boolean} - returns {value} or a boolean if {value} is "true" || "false"
 */
const getValFromBooleanMapping = (value: string) => {
	const mapping: Record<string, boolean> = { true: true, false: false };

	return mapping[value] || value;
};
/**
 * Takes in a map of url params (e.g. { user: "george" })
 * Returns same map but
 * 1) it strips away hashes from last param in URL
 * 2) Converts "true" and "false" to corresponding Boolean vals
 * 3) Removes empty strings
 *
 * @param inputObject - map of url params
 * @returns {LooseObject} - cleaned up url params
 */
const parseURLParams = (inputObject: LooseObject): LooseObject => {
	return Object.keys(inputObject).reduce((acc, paramName) => {
		const value: string = inputObject[paramName];
		const sanitisedVal = excludeHashValues(value);

		if (sanitisedVal === '') return acc;

		// if params contain true/false strings, convert them to booleans
		if (sanitisedVal === 'true' || sanitisedVal === 'false') {
			const mappedVal = getValFromBooleanMapping(sanitisedVal);

			return {
				...acc,
				[paramName]: mappedVal,
			};
		}

		// if params contain one or more parts of the source array (ex. googleAdsConversionIds[0]='aw-123/abasdf'), go through them and patch them back into an array
		if (paramName.includes('[')) {
			paramName = paramName.split('[')[0];

			return {
				...acc,
				[paramName]: makeArray(paramName, sanitisedVal, acc),
			};
		}

		// otherwise, return the parameter
		return {
			...acc,
			[paramName]: sanitisedVal,
		};
	}, {});
};
/**
 * Returns query string from a passed url
 * @param url - a full URL
 * @returns {string} returns only query params from URL or empty string if no query params present
 */
const getQueryString = (url: string): string => {
	if (!url) return '';

	const matches = url.match(/\?(.*)/);

	if (!matches || !matches.length || !matches[1]) return '';

	return matches[1];
};

export const urlToQueryStringObject = (url: string): LooseObject => {
	const queryString = getQueryString(url);
	const queryStringObject = qs.parse(queryString);

	if (queryStringObject.webPropertyID) {
		// TODO - delete when inconsistency in case is fixed
		queryStringObject.webPropertyId = queryStringObject.webPropertyID;
	}

	return parseURLParams(queryStringObject);
};

const makeArray = (inputKey: string, inputString: string, inputObject: LooseObject = {}): string[] => {
	let arrayVal: string[];

	(arrayVal = inputObject[inputKey] !== undefined ? inputObject[inputKey] : []).push(inputString);

	return arrayVal;
};
